<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <div class="spinner" v-show="isLoading"><div><div></div></div></div>
        <!-- Header -->
        <section class="header point_get">
            <!-- Left -->
            <div class="flexH width">
                <a class="icon iconButton" @click.prevent="goBack()">
                    <font-awesome-icon icon="fas fa-chevron-left"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">獲得{{pointName}}</span>
            <!-- Right -->
            <div class="flexH width right"></div>
        </section>
        <!-- Main -->
        <div class="main point_get">
            <div class="card">
                <div class="point flexH">
                    <img :src="pointImage" />
                    <span class="fXXL">{{transferInfo.value}}</span>
                </div>
                <span class="code fM gray">{{transferInfo.entryCode}}</span>
                <div class="info flexV end">
                    <span class="fL">{{transferInfo.name}}</span>
                    <span class="fM">截止日：{{tsToDate(transferInfo.expiredTs)}}</span>
                </div>
            </div>
            <div class="flexV center">
                <span class="fL">確定加入帳號？</span>
                <span class="fM gray">選擇加入後即無法修改</span>
            </div>
            <div class="flexH width middle">
                <a class="button rounded white" @click.prevent="goBack">
                    <span class="fM">取消</span>
                </a>
                <a class="button rounded" @click.prevent="toTransfer">
                    <span class="fM">加入帳號</span>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from "vuex";

export default {
    name: "",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            transferCode: this.$route.query.transferCode,
            transferInfo: {
                value: "",
                type: "",
                entryCode: "",
                name: "",
                expiredTs: null
            },
            isLoading: false
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        if (from.path === '/code-exchange') {
            to.meta.parent = '/code-exchange';
        } else if (from.path === '/' ) {
            to.meta.parent = localStorage.getItem('parentPath') || '/scanner';
        } else {
            to.meta.parent = '/scanner';
        }
        localStorage.setItem('parentPath', to.meta.parent);
        next();
    },
    computed: {
        ...mapState(["user"]),
        pointImage() {
            return process.env.VUE_APP_POINT_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_POINT_IMAGE_PATH}`) : require("@/assets/icon/point.svg");
        }, 
        pointName() {
            return process.env.VUE_APP_POINT_NAME?.trim() ? process.env.VUE_APP_POINT_NAME?.trim() : '紅利桃子';
        }, 
    },
    mounted() {
        this.getTransferCheckInfo()
    },
    methods: {
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        goBack() {
            // window.history.length > 1
            //     ? this.$router.go(-1)
            //     : this.$router.push("/");
            let parent = this.$route.meta.parent || '/scanner';
            this.$router.push(parent);
        },
        depositHandler() {
            console.log("depositHandler: ", depositHandler);
        },
        transferCheckInfoAPI() {
            let data = JSON.stringify({
                entryCode: this.transferCode
            });
            var config = {
                method: 'post',
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/entries/check`,
                data : data
            };

            return this.$http(config)
                
        },
        dummyTransferCheckInfo() {
            let result = {
                "entryCode": "X9QY98E6K9HP3ZHY",
                "campaignId": "C_XXXX",
                "missionId": 1,
                "name": "點數兌換代碼",
                "type": "point",
                "value": "100",
                "status": "availabled",
                "expiredTs": 1652206792,
                "createdTs": 1652206792,
                "updatedTs": 1652206792
            };
            let promise = new Promise((resolve) => {
                resolve({data: result});
            });
            return promise;
        },
        getTransferCheckInfo() {
            this.isLoading = true;
            // this.dummyTransferCheckInfo()
            this.transferCheckInfoAPI()
                .then( (response) => {
                    this.isLoading = false;
                    console.log(response.data);
                    this.transferInfo = response.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                    this.$router.push('/scan-error');
                });
        },
        transferAPI() {
            var data = JSON.stringify({
                "entryCode": this.transferCode
            });

            var config = {
                method: 'post',
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/entries`,
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };

            return this.$http(config);
        },
        dummyTransfer() {
            let result = {
                "success": true,
                "name": "桃園市活動",
                "entryCode": "Aq8b7OZMgI5WGC8",
                "type": "point",
                "value": "100",
                "redeemedTs": 12345789
            };
            let promise = new Promise((resolve) => {
                resolve({data: result});
            });
            return promise;
        },
        toTransfer() {
            // this.dummyTransfer()
            this.transferAPI()
            .then(res => {
                this.$router.push({
                    path: "/transfer-result",
                    query: res.data
                });
            })
        }
    }
};
</script>
